import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  // initialize() {
  //   console.log(this.element.dataset.orderCompleted);
  //   if (this.element.dataset.orderCompleted === 'true') {
  //     window.location = '/user_verifications';
  //   }
  // }
}
